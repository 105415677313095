<template>
  <div>
    <Header>
      <template v-slot:title>
        {{ $t("client-list") }}
      </template>
    </Header>
    <div
      v-if="get_ib_profile.state === 'Approved'"
      class="bg-white shadow rounded-md min-h-screen m-auto max-w-7xl p-0 pb-16 sm:mt-5 relative"
    >
      <Table :tableData="get_client_list.results" :title="$t('client-list')" />
      <pagination
        v-if="get_client_list && get_client_list.count"
        :clientPagination="true"
        :pagination="paginationClientList"
        :results="get_client_list.count"
        class="border-t-none border-b"
        @new="newPage"
        @next="nextPage"
        @previous="previousPage"
      ></pagination>
      <Table
        :tableData="get_sub_ib_client_list.results"
        :title="$t('sub-ibs-clients-list')"
      />
      <pagination
        v-if="get_sub_ib_client_list && get_sub_ib_client_list.count"
        :clientPagination="true"
        :pagination="paginationSubIbClientList"
        :results="get_sub_ib_client_list.count"
        class="border-t-none border-b"
        @new="newPageSubIb"
        @next="nextPageSubIb"
        @previous="previousPageSubIb"
      ></pagination>
      <Table :tableData="get_sub_ib_list.results" :title="$t('sub-ib-list')" />
      <pagination
        v-if="get_sub_ib_list && get_sub_ib_list.count"
        :clientPagination="true"
        :pagination="paginationIbList"
        :results="get_sub_ib_list.count"
        class="border-t-none border-b"
        @new="newPageIb"
        @next="nextPageIb"
        @previous="previousPageIb"
      ></pagination>
    </div>
    <ib-approval-alert v-if="get_ib_profile.state === 'Pending'" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "@/components/Header";
import Table from "@/views/ib/trading/client-list/Table";
import Pagination from "@/components/Pagination";
import IbApprovalAlert from "@/components/IbApprovalAlert";

export default {
  name: "ClientList",
  components: {
    Header,
    Table,
    Pagination,
    IbApprovalAlert,
  },
  data() {
    return {
      paginationClientList: {
        page_size: 10,
        page: 1,
      },
      paginationSubIbClientList: {
        page_size: 10,
        page: 1,
      },
      paginationIbList: {
        page_size: 10,
        page: 1,
      },
    };
  },
  computed: {
    ...mapGetters([
      "get_client_list",
      "get_sub_ib_client_list",
      "get_sub_ib_list",
      "get_ib_profile",
    ]),
  },
  created() {
    this.$store.dispatch("client_list", this.paginationClientList);
    this.$store.dispatch("sub_ib_client_list", this.paginationSubIbClientList);
    this.$store.dispatch("sub_ib_list", this.paginationIbList);
  },
  methods: {
    // NEW PAGE
    newPage(data) {
      this.paginationClientList = data;
      this.$store.dispatch("client_list", this.paginationClientList);
    },
    newPageSubIb(data) {
      this.paginationSubIbClientList = data;
      this.$store.dispatch(
        "sub_ib_client_list",
        this.paginationSubIbClientList
      );
    },
    newPageIb(data) {
      this.paginationIbList = data;
      this.$store.dispatch("sub_ib_list", this.paginationIbList);
    },

    //PREVIOUS PAGE
    previousPage() {
      // this is because backend send wrong link

      if (this.get_client_list.previous !== null) {
        const data = {
          page_size: this.paginationClientList.page_size,
          page: this.paginationClientList.page - 1,
        };
        this.$store.dispatch("client_list", data);
      }
    },

    previousPageSubIb() {
      if (this.get_sub_ib_client_list.previous !== null) {
        const data = {
          page_size: this.paginationSubIbClientList.page_size,
          page: this.paginationSubIbClientList.page - 1,
        };
        this.$store.dispatch("sub_ib_client_list", data);
      }
    },

    previousPageIb() {
      if (this.get_sub_ib_list.previous !== null) {
        const data = {
          page_size: this.paginationIbList.page_size,
          page: this.paginationIbList.page - 1,
        };
        this.$store.dispatch("sub_ib_list_previous", data);
      }
    },

    // NEXT PAGE
    nextPage() {
      if (this.get_client_list.next !== null)
        this.$store.dispatch("client_list_previous", this.get_client_list.next);
    },
    nextPageSubIb() {
      if (this.get_sub_ib_client_list.next !== null)
        this.$store.dispatch(
          "sub_ib_client_list_previous",
          this.get_sub_ib_client_list.next
        );
    },
    nextPageIb() {
      if (this.get_sub_ib_list.next !== null)
        this.$store.dispatch("sub_ib_list_previous", this.get_sub_ib_list.next);
    },
  },
};
</script>