<template>
  <div>
    <div class="flex justify-between p-6 mt-8">
      <h3 class="text-lg leading-6 font-medium text-gray-700">
        {{ title }}
      </h3>
      <span class="inline-flex rounded-md shadow-sm ml-3">
        <button
          class="relative inline-flex items-center pr-4 pl-1 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
          type="button"
        >
          <download-csv
            :data="tableData"
            :name="
              title === 'Client list'
                ? 'Invited Users.csv'
                : 'IB Invited Users.csv'
            "
            class="flex text-gray-700"
          >
            <svg
              class="w-5 h-5 mr-2 text-gray-700"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
            {{ $t("export-to-csv") }}
          </download-csv>
        </button>
      </span>
    </div>
    <div class="flex flex-col">
      <div class="overflow-x-auto">
        <div class="align-middle inline-block min-w-full">
          <div class="overflow-hidden border-b border-gray-200">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ $t("user-id") }}
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ $t("first-name") }}
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ $t("last-name") }}
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ $t("Email") }}
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ $t("Phone") }}
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ $t("Status") }}
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ $t("Commission") }}
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ $t("total-deposits") }}
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ $t("total-withdrawals") }}
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ $t("link-name") }}
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ $t("date-joined") }}
                  </th>
                </tr>
              </thead>
              <tbody
                v-for="(item, index) in tableData"
                :key="index"
                class="bg-white divide-y divide-gray-200"
              >
                <tr>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                  >
                    {{ item.id }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                  >
                    {{ item.first_name }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                  >
                    {{ item.last_name }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                  >
                    {{ item.email }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                  >
                    {{ item.telephone }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                  >
                    {{ item.state }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                  >
                    <money-format
                      v-if="item.total_commission"
                      :currency-code="get_profile.profile.currency"
                      :value="item.total_commission"
                    >
                    </money-format>
                    <money-format
                      v-else
                      :currency-code="get_profile.profile.currency"
                      :value="0"
                    >
                    </money-format>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                  >
                    <money-format
                      :currency-code="get_profile.profile.currency"
                      :value="item.total_deposit"
                    >
                    </money-format>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                  >
                    <money-format
                      :currency-code="get_profile.profile.currency"
                      :value="item.total_withdrawal"
                    >
                    </money-format>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                  >
                    {{ item.link.name }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                  >
                    {{ item.date_joined | moment("MM-DD-YYYY") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MoneyFormat from "vue-money-format";

export default {
  name: "Table",
  components: {
    "money-format": MoneyFormat,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
      required: true,
      default: () => {
        return "";
      },
    },
  },
  computed: {
    ...mapGetters(["get_profile"]),
  },
};
</script>